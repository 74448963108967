<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t("CustomPage.NewButton", {}, { locale: this.$store.state.activeLang })
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :rows="customPages"
    @changeOrderSuccessful="getCustomPages"
  />
</template>
<script>
export default {
  name: "CustomPageList",
  components: {},
  data() {
    return {
      customPages: [],
      gridSettings: {
        action: "CustomPageList",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "customPageId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/CustomPage/EditHtmlScriptCss/`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-CustomPageDelete",

        isGatewayRequest: true,

        allowDragAndDrop: true,
        changeOrderUrl: "/Lcdp-CustomPageChangeOrder",
        changeOrderModelParentProperties: [],
        autoOrderProcess: false,

        buttons: [
          {
            name: "html-script",
            cssClass: "btn-primary",
            iconClass: "bi-braces",
            routeButton: true,
            routeObj: {
              name: "CustomPageEditHtmlScriptCss",
              params: {
                customPageId: "",
              },
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomPageEdit",
              params: {
                customPageId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "CustomPage.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomPage.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "CustomPage.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        // {
        //   text: this.$t(
        //     "CustomPage.isDefault",
        //     {},
        //     { locale: this.$store.state.activeLang }
        //   ),
        //   field: "isDefault",
        //   type: "boolean",
        //   visible: true,
        //   width: 300,
        //   template: "",
        //   format: "",
        //   textAlign: "",
        // },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    getCustomPages() {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-CustomPageList"))
        .then((response) => {
          this.customPages = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getCustomPages();
  },
};
</script>
